var _LanguageUtils;function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}
import { availableLanguages, otherLanguageSchema } from "./availableLanguages";

class LanguageUtils {
  static createLanguageIndex(code) {
    return availableLanguages.reduce(
      (prev, next) => {
        if (!next[code]) return prev;

        return _objectSpread(_objectSpread({},
        prev), {}, {
          [next[code]]: _objectSpread({}, next) });

      },
      {}
    );
  }







  static uniqueValues(array) {
    return Array.from(new Set(array));
  }

  static getByCode(code = 'elastic') {
    return availableLanguages.filter((item) => Boolean(item[code]));
  }

  static getCodes(languages, languageCode) {
    return this.uniqueValues(languages.map((item) => item[languageCode]));
  }

  static fromISO639_3(ISO639_3, enableFallback = true) {
    const fallback = enableFallback ? otherLanguageSchema : null;

    return this.ISO639_3Index[ISO639_3] || fallback;
  }

  static fromISO639_1(ISO639_1) {
    return this.ISO639_1Index[ISO639_1] || null;
  }

  static fromElastic(elastic) {
    return this.elasticIndex[elastic] || otherLanguageSchema;
  }
}_LanguageUtils = LanguageUtils;_defineProperty(LanguageUtils, "ISO639_3Index", _LanguageUtils.createLanguageIndex('ISO639_3'));_defineProperty(LanguageUtils, "ISO639_1Index", _LanguageUtils.createLanguageIndex('ISO639_1'));_defineProperty(LanguageUtils, "elasticIndex", _LanguageUtils.createLanguageIndex('elastic'));

export { LanguageUtils };