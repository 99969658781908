
import { LanguageUtils } from "./language";

export const entityDefaultDocument = (
entityDocuments,
entityLanguage,
defaultLanguage) =>
{
  const documents = entityDocuments || [];
  const documentMatchingEntity = documents.find(
    (document) => {var _LanguageUtils$fromIS;return (
        document.language &&
        ((_LanguageUtils$fromIS = LanguageUtils.fromISO639_3(document.language)) === null || _LanguageUtils$fromIS === void 0 ? void 0 : _LanguageUtils$fromIS.ISO639_1) === entityLanguage);}
  );

  const documentMatchingDefault = documents.find(
    (document) => {var _LanguageUtils$fromIS2;return (
        document.language &&
        ((_LanguageUtils$fromIS2 = LanguageUtils.fromISO639_3(document.language)) === null || _LanguageUtils$fromIS2 === void 0 ? void 0 : _LanguageUtils$fromIS2.ISO639_1) === defaultLanguage);}
  );

  return documentMatchingEntity || documentMatchingDefault || documents[0];
};